@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Regular/Gilroy-Regular.eot');
    src: url('Gilroy-Regular/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Regular/Gilroy-Regular.woff2') format('woff'),
    url('Gilroy-Regular/Gilroy-Regular.woff') format('woff'),
    url('Gilroy-Regular/Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Medium/Gilroy-Medium.eot');
    src: url('Gilroy-Medium/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Medium/Gilroy-Medium.woff2') format('woff2'),
    url('Gilroy-Medium/Gilroy-Medium.woff') format('woff'),
    url('Gilroy-Medium/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Semibold/Gilroy-Semibold.eot');
    src: url('Gilroy-Semibold/Gilroy-Semibold.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Semibold/Gilroy-Semibold.woff2') format('woff2'),
    url('Gilroy-Semibold/Gilroy-Semibold.woff') format('woff'),
    url('Gilroy-Semibold/Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Bold/Gilroy-Bold.eot');
    src: url('Gilroy-Bold/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Bold/Gilroy-Bold.woff2') format('woff2'),
    url('Gilroy-Bold/Gilroy-Bold.woff') format('woff'),
    url('Gilroy-Bold/Gilroy-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}